const buildECommercePlans = apiPlans => {
  const builtPlans = {}

  builtPlans.id = apiPlans.id
  builtPlans.name = buildName(apiPlans)
  builtPlans.period = buildPlanPeriod(apiPlans)
  builtPlans.isActive = buildIsActive(apiPlans)
  builtPlans.isUpgradable = buildIsUpgradable(apiPlans)
  builtPlans.isDowngradable = buildIsDowngradable(apiPlans)
  builtPlans.historyDays = buildHistoryDays(apiPlans)
  builtPlans.hdCountPerMonth = buildHDCount(apiPlans)
  builtPlans.photoCountPerMonth = buildPhotoCount(apiPlans)
  builtPlans.videoCountPerMonth = buildVideoCount(apiPlans)
  builtPlans.eligibleCameras = buildEligibleCameras(apiPlans)

  builtPlans.price = buildPrice(apiPlans)
  builtPlans.discount = apiPlans.name.includes('Annual') && buildDiscount(apiPlans)
  builtPlans.stepQuantity = apiPlans.stepQuantity
  builtPlans.inventory = apiPlans.inventory
  builtPlans.primaryCategoryId = apiPlans.primaryCategoryId

  return builtPlans
}

const buildECommerceAddons = apiAddons => {
  const builtAddons = {}

  builtAddons.id = apiAddons.id
  builtAddons.name = buildAddonName(apiAddons)
  builtAddons.minutesHd = buildMinuteHd(apiAddons)
  builtAddons.minutesSd = buildMinuteSd(apiAddons)
  builtAddons.eligibleCameras = buildAddonEligibleCameras(apiAddons)

  builtAddons.price = apiAddons.price
  builtAddons.stepQuantity = apiAddons.stepQuantity
  builtAddons.inventory = apiAddons.inventory
  builtAddons.primaryCategoryId = apiAddons.primaryCategoryId

  return builtAddons
}

const buildName = apiPlans => apiPlans.c_dataPlanName
const buildEligibleCameras = apiPlans => apiPlans.c_eligibleCameras
const buildIsActive = apiPlans => apiPlans.c_isActive
const buildIsUpgradable = apiPlans => apiPlans.c_isUpgradable
const buildIsDowngradable = apiPlans => apiPlans.c_isDowngradable
const buildHistoryDays = apiPlans => apiPlans.c_historyDays
const buildHDCount = apiPlans => apiPlans.c_hdCountPerMonth
const buildPhotoCount = apiPlans => apiPlans.c_photoCountPerMonth
const buildVideoCount = apiPlans => apiPlans.c_videoCountPerMonth
const buildPlanPeriod = apiPlans => apiPlans.name.includes('Annual') ? 'annual' : 'month_by_month'
const buildPrice = apiPlans => apiPlans.name.includes('Annual') ? apiPlans?.productPromotions[0]?.promotionalPrice : apiPlans.price
const buildDiscount = apiPlans => apiPlans.price / (apiPlans.price - apiPlans?.productPromotions[0]?.promotionalPrice) + '%'

const buildAddonName = apiAddons => apiAddons.c_addonName
const buildMinuteHd = apiAddons => apiAddons.c_minutesHd
const buildMinuteSd = apiAddons => apiAddons.c_minutesSd
const buildAddonEligibleCameras = apiAddons => apiAddons.c_addonsEligibleCamera

const buildBaskets = apiBasket => {
  const builtBasket = {}

  builtBasket.id = apiBasket.id
  builtBasket.name = buildName(apiBasket)
  builtBasket.totalPrice = apiBasket.totalPrice

  builtBasket.items = apiBasket.items.map(item => ({
    id: item.id,
    name: buildName(item),
    quantity: item.quantity,
    price: item.price,
  }))

  return builtBasket
}

const eCommerceBuilder = { buildECommercePlans, buildECommerceAddons, buildBaskets }
export default eCommerceBuilder
