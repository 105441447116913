import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import { useFormData } from 'shared-module/new-components/form/FormDataContext'
import useUserActions from 'user-module/user/core/useUserActions'
import FormButton from 'user-module/user/ui/login/components/FormButton'
import useLoginRedirect from 'user-module/user/core/useLoginRedirect'
import EmailField from 'storybook-component-module/src/wrappers/fields/EmailField'
import PasswordField from 'storybook-component-module/src/wrappers/fields/PasswordField'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'
import { useEffect } from 'react'
import cameraSharingActions from 'camera-module/camera/core/CameraSharingActions'
import useQueryParams from 'shared-module/url/useQueryParams'
import { useDispatch } from 'react-redux'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import eCommerceActions from 'ecommerce-module/core/eCommerce.actions'

const useStyles = makeStyles(theme => ({
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background?.paper,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      marginBottom: '0.25rem',
    },
  },
}))

const LoginPageForm = ({ isNewUi = false }) => {
  const { t } = useTranslation()
  const form = useFormData()
  const { v, accept } = useQueryParams()
  const dispatch = useDispatch()
  const userActions = useUserActions()
  const redirection = useLoginRedirect()
  const classes = useStyles()
  const importedClasses = VoskerInputStyles()

  const requiredPassword = { required: t('input.password_required') }

  const login = (data) => {
    const { email, password } = data

    userActions.login(email, password)
      .then(() => redirection.redirect())
      .then(() => eCommerceActions.authenticateUser(),
      ).catch(e => {
        form.setError([
          { name: 'email', message: '' },
          { name: 'password', message: t('common:login.unauthorized') }])
      })
  }

  useEffect(() => {
    if (accept === true) {
      dispatch(cameraSharingActions.acceptInvitation(v))
    } else if (accept === false) {
      dispatch(cameraSharingActions.rejectInvitation(v))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Empty array ensures this runs only once after first render

  return (
    <form onSubmit={form.handleSubmit(login)}>
      <Box display="flex" flexDirection="column">
        <EmailField
          isNewUi={isNewUi}
          fieldName="email"
          validations
          className={isNewUi ? clsx(importedClasses.input, classes.input) : ''}
        />

        <PasswordField
          isNewUi={isNewUi}
          fieldName="password"
          validations
          validationSchema={requiredPassword}
          className={isNewUi ? clsx(importedClasses.input, classes.input) : ''}
        />

        <FormButton isNewUi={isNewUi}>{ t('common:login.form.button') }</FormButton>
      </Box>
    </form>
  )
}

export default LoginPageForm
